import Service from "../Service";

const resource = "SecDesktopNotification/";

export default {
    save(item, requestID) {
        return Service.post(resource + "save", item, {
            params: { requestID: requestID },
        });
    },

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

};