<template lang="">
    <v-card>
        
        <s-crud
            :config="config"
            title="Notificaciones"
            :filter="filter"
            add
            edit
            remove
            @save="saveScaffold($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>                        
                        <v-col>
                            <s-text
                                label="Codigo"
                                v-model="filter.SdnDescription"
                            ></s-text>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >                                        
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text
                                label="Titulo*" 
                                v-model="props.item.SdnTitle"/>
                        </v-col> 
                         <v-col class="s-col-form" sm="3" md="3">
                            <s-text                                
                                label="Descripcion*" 
                                v-model="props.item.SdnDescription"/>
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date                                
                                label="Date*" 
                                v-model="props.item.SdnDateEnd"/>
                        </v-col>
                    </v-row>
                     
                </v-container>
            </template>
        </s-crud>
    </v-card>
</template>

<script>
    import ServiceNotifi from '@/services/Security/SecDesktopNotificationService'

    export default {
        components: {
         
        },
        data() {
            return {
                filter:{
                    ScfCode: "",
                    UsrID: 0
                },
                config: {
                    service: ServiceNotifi,
                    model: {
                        SdnID : "ID"
                    },
                    headers: [
                        { text: "ID", value: "SdnID" },
                        { text: "Titulo", value: "SdnTitle" },
                        { text: "Descripcion", value: "SdnDescription" },
                        { text: "Fecha de Fin", value: "SdnDateEnd" },
                    ]  
                }  
            }
        },
        methods:{
            saveScaffold(item){
                
            console.log(item);
            if(item.SdnTitle.length <= 3){
                this.$fun.alert("El campo Codigo es Obligatorio","warning");
                return;     
            }

                item.save();
            }        
        },
        mounted(){
            this.filter.UsrID = this.$fun.getUserID();
        }
    }

</script>